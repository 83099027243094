// import Header from "../components/Header";
import EventHistory from "../components/eventHistory";
import Comments from "../components/comments";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ios from "../page/app/img/ios.png";
import adroi from "../page/app/img/adroi.png";
import { BsFillHeartFill } from "react-icons/bs";
import { SlMenu } from "react-icons/sl";
import useEvenStore from "../../utils/store";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import platform from "platform";
import HighlightVideo from "../components/HighlightVideo";
import TrackUsers from "../components/TrackUsers";
import ReactGA from "react-ga4";

ReactGA.send({
  hitType: "Home",
  page: "/",
  title: "Home",
});

function Historyv2() {
  const [search_w, keyWord] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const version = useEvenStore((state) => state.version);
  const setVersion = useEvenStore((state) => state.setVersion);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const toggleVersion = () => {
    navigate("/love");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [link, setLink] = useState("");
  const [activeTab, setActiveTab] = useState("events");

  useEffect(() => {
    const os = platform.os.family;

    if (os === "iOS") {
      setModalContent(ios);
      setIsModalOpen(true);
      setLink(
        "https://apps.apple.com/us/app/futurelove-ai-love-future/id6463770787"
      );
    } else if (os === "Android") {
      setModalContent(adroi);
      setIsModalOpen(true);
      setLink(
        "https://play.google.com/store/apps/details?id=com.thinkdiffai.futurelove"
      );
    } else {
      console.log("Đây là laptop");
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSearch = (value) => {
    if (value === "") {
      axios
        .get(`https://databaseswap.mangasocial.online/lovehistory/page/1`)
        .then((response) => {
          setDataSearch(response.data.list_sukien);
        });
    }
    axios
      .get(`https://databaseswap.mangasocial.online/search?word=${value}`)
      .then((response) => {
        setDataSearch(response.data.list_sukien);
      });
  };
  return (
    <div
      className="flex flex-col min-h-screen overflow-hidden Historyv2"
      style={{ background: "linear-gradient(to right, #F0A3BF, #A86ED4)" }}
    >
      <Header onSearch={onSearch} />

      <div>
        <div className="mt-5 mb-3">
          <b className="ml-12 text-3xl text-white starborn lg:text-5xl">
            Highlight Videos
          </b>
        </div>
        <div className="mx-6 sm:mx-10 lg:mx-16">
          <HighlightVideo />
        </div>
      </div>

      <div className="mt-[50px]">
        {/* Tab buttons */}
        <div className="flex justify-center space-x-4 tabs">
          <button
            onClick={() => setActiveTab("events")}
            className={`px-4 py-2 text-3xl font-medium rounded-md transition-colors ${
              activeTab === "events"
                ? "bg-purple-600 text-white"
                : "bg-white text-purple-600 hover:bg-purple-500 "
            }`}
          >
            Events
          </button>
          <button
            onClick={() => setActiveTab("newUser")}
            className={`px-4 py-2 text-3xl font-medium rounded-md transition-colors ${
              activeTab === "newUser"
                ? "bg-purple-600 text-white"
                : "bg-white text-purple-600 hover:bg-purple-500 "
            }`}
          >
            Track Users
          </button>
        </div>

        {/* Tab content */}
        {activeTab === "events" && (
          <div>
            <div className="mt-5 mb-3">
              <b className="ml-12 text-3xl text-white starborn lg:text-5xl">
                Events
              </b>
            </div>

            <div className="grid justify-center grid-cols-1 gap-8 mx-6 lg:grid-cols-2 sm:mx-10 lg:mx-16">
              <div className="flex justify-center">
                <div className="flex-grow">
                  <EventHistory search={search_w} data={dataSearch} />
                </div>
              </div>
              <div>
                <Comments />
              </div>
            </div>
          </div>
        )}
        {activeTab === "newUser" && (
          <div>
            <div className="mt-5 mb-3">
              <b className="ml-12 text-3xl text-white starborn lg:text-5xl">
                Track Users
              </b>
            </div>

            <div className="flex flex-col w-[75%] m-auto lg:grid-cols-2 sm:mx-10">
              <TrackUsers />
            </div>
          </div>
        )}
      </div>

      <Footer />
      {isModalOpen && (
        <div className="absolute inset-0 flex items-center justify-center ">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="z-10 p-8 text-center bg-white rounded-md">
            <h2 className="mb-4 text-2xl font-bold">Dowload App</h2>
            <Link to={link}>
              <img src={modalContent} alt="ios" style={{ width: "200px" }} />
            </Link>
            <button
              onClick={closeModal}
              className="px-4 py-2 mt-4 text-white bg-red-500 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Historyv2;
