import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function TrackUsers() {
  const [todayUsers, setTodayUsers] = useState([]);
  const [oneDayUsers, setOneDayUsers] = useState([]);
  const [twoDaysUsers, setTwoDaysUsers] = useState([]);
  const [monthUsers, setMonthUsers] = useState([]);

  const [activeTab, setActiveTab] = useState("today");
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10; // Number of users per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://datanomic.online/api/users/get_by_time/day"
        );
        const monthResponse = await axios.get(
          "https://datanomic.online/api/users/get_by_time/month"
        );

        // Assuming the API returns data in the expected format
        setTodayUsers(
          sortUsersByTimeCreated(
            response.data["Account has been created today"]
          ) || []
        );
        setOneDayUsers(
          sortUsersByTimeCreated(
            response.data["Account has been created within 1 day"]
          ) || []
        );
        setTwoDaysUsers(
          sortUsersByTimeCreated(
            response.data["Account has been created within 2 days"]
          ) || []
        );
        setMonthUsers(
          sortUsersByTimeCreated(
            monthResponse.data["Account has been created within 30 days"]
          ) || []
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const sortUsersByTimeCreated = (users) => {
    return users.sort(
      (a, b) => new Date(b.time_create) - new Date(a.time_create)
    );
  };

  const getUsersList = (tab) => {
    const lists = {
      today: todayUsers,
      oneDay: oneDayUsers,
      twoDays: twoDaysUsers,
      month: monthUsers,
    };
    return lists[tab] || [];
  };

  const pageCount = Math.ceil(getUsersList(activeTab).length / usersPerPage);

  const displayedUsers = getUsersList(activeTab).slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <Container>
      <div className="flex flex-col">
        <div className="flex my-4">
          <div className="tabs">
            <TabButton
              active={activeTab === "today"}
              onClick={() => setActiveTab("today")}
            >
              Today
            </TabButton>
            <TabButton
              active={activeTab === "oneDay"}
              onClick={() => setActiveTab("oneDay")}
            >
              1 Day
            </TabButton>
            <TabButton
              active={activeTab === "twoDays"}
              onClick={() => setActiveTab("twoDays")}
            >
              2 Days
            </TabButton>
            <TabButton
              active={activeTab === "month"}
              onClick={() => setActiveTab("month")}
            >
              Month
            </TabButton>
          </div>
        </div>

        <UsersTable users={displayedUsers} navigate={navigate} />
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName="flex items-center justify-center my-4 space-x-2 text-2xl"
        pageLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white rounded-md hover:bg-gray-100"
        previousLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white rounded-md hover:bg-gray-100"
        nextLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white rounded-md hover:bg-gray-100"
        activeLinkClassName="text-blue-600 leading-tight active"
      />
    </Container>
  );
}

function TabButton({ children, active, onClick }) {
  return (
    <button
      className={`tab mr-3 px-4 py-2 rounded-md text-2xl font-medium bg-white text-blue-500 ${
        active ? "active" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function UsersTable({ users, navigate }) {
  return (
    <table className="leading-normal min-w-fit table_background">
      <thead className="text-center">
        <tr>
          <th className="p-2 text-3xl border border-slate-300">Avatar</th>
          <th className="p-2 text-3xl border border-slate-300">User Name</th>
          <th className="p-2 text-3xl border border-slate-300">Email</th>
          <th className="p-2 text-3xl border border-slate-300">Device</th>
          <th className="p-2 text-3xl border border-slate-300">Time Created</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr
            key={user.id_user}
            style={{ cursor: "pointer" }}
            className="hover:bg-[#ffdefc]"
            onClick={() => navigate(`/user/${user.id_user}`)}
          >
            <td className="p-2 border border-slate-300">
              <img
                src={user.link_avatar}
                alt={user.user_name}
                className="w-[5rem] rounded-full h-[5rem] relative left-[50%] translate-x-[-50%]"
              />
            </td>
            <td className="p-2 text-3xl border border-slate-300">
              {user.user_name}
            </td>
            <td className="p-2 text-3xl border border-slate-300">
              {user.email}
            </td>
            <td className="p-2 text-3xl border border-slate-300">
              {user.device_register}
            </td>
            <td className="p-2 text-3xl border border-slate-300">
              {formatDistanceToNow(new Date(user.time_create), {
                addSuffix: true,
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TrackUsers;

const Container = styled.div`
  .table_background {
    box-shadow: 0px 0px 10px rgba(114, 114, 113, 0.5);
    border-radius: 15px;
  }

  table {
    font-family: futura-pt, sans-serif;
    border-radius: 15px;
    overflow: hidden;
  }

  tr:nth-child(even) {
    background-color: #ffdefc;
  }

  .active {
    color: #fff !important;
    background-color: #3b82f7 !important;
  }
`;
